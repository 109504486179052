import Container from '@mui/material/Container';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import NewRegistrationForm from './new-registration-form';

export default function AddRegistrationView() {
  const { t } = useLocales();
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('new_registration.registration_title')}
        links={[
          { name: t('dashboard'), href: paths.hssfPaths.root },
          {
            name: t('new_registration.registration_title'),
            href: paths.hssfPaths.registration.list,
          },
          {
            name: t('registration'),
            href: paths.hssfPaths.registration.list,
          },
        ]}
        sx={{ mb: { xs: 3, md: 5 }, my: 2.5 }}
      />
      <NewRegistrationForm />
    </Container>
  );
}
