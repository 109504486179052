import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
// import Label from 'src/components/label';
// import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { ROLES } from 'src/utils/utils';

// ----------------------------------------------------------------------
// const icon = (name) => (name ? <Iconify icon={name} /> : <Iconify icon="mage:dashboard-fill" />);

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  // hssf
  addUser: icon('ic_add_user'),
  shanshtha: icon('ic_shanshtha'),
  dashboard_2: icon('ic_dashboard_2'),
  event: icon('ic_event'),
  event_cal: icon('ic_event_cal'),
  ic_fair: icon('ic_fair'),
  ic_team_management: icon('ic_team_management'),
  ic_institution: icon('ic_institution'),
  ic_report: icon('ic_report'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // ---------------------------------------------------------------------------------- HSSF-START
      {
        subheader: 'HSSF',
        items: [
          // ! new - start
          {
            title: t('dashboard'),
            path: paths.hssfPaths.root,
            icon: ICONS.dashboard,
            roles: [
              ROLES.superAdmin,
              ROLES?.prant,
              ROLES.vibhag,
              ROLES.jilla,
              ROLES.taluka,
              ROLES.karykarta,
            ],
          },
          // fair
          // {
          //   title: `${t('fair')} - ${getFairYear()}`,
          //   path: paths.hssfPaths.fair.root,
          //   // path: '#',
          //   // disabled: true,
          //   icon: ICONS.ic_fair,
          //   roles: [ROLES.superAdmin, ROLES?.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka],
          //   commonActive: 'fair',
          // },
          // // fair
          {
            title: t('team_management'),
            path: paths.hssfPaths.teamManagement.root,
            // path: '#',
            // disabled: true,
            icon: ICONS.ic_team_management,
            roles: [ROLES?.superAdmin],
          },
          // {
          //   title: t('gujarat_prant'),
          //   path: paths.hssfPaths.prant.root,
          //   // path: '#',
          //   // disabled: true,
          //   icon: ICONS.dashboard_2,
          //   commonActive: 'view',
          //   roles: [ROLES?.prant],
          // },
          // {
          //   title: t('vibhag'),
          //   path: paths.hssfPaths.vibhag.root,
          //   // path: '#',
          //   // disabled: true,
          //   icon: ICONS.dashboard_2,
          //   commonActive: 'view',
          //   roles: [ROLES.vibhag],
          // },
          // {
          //   title: t('jilla'),
          //   path: paths.hssfPaths.jilla.root,
          //   // path: '#',
          //   // disabled: true,
          //   icon: ICONS.dashboard_2,
          //   commonActive: 'view',
          //   roles: [ROLES.jilla],
          // },
          // {
          //   title: t('taluka'),
          //   path: paths.hssfPaths.taluka.root,
          //   icon: ICONS.dashboard_2,
          //   commonActive: 'view',
          //   // roles: [ROLES.taluka],
          //   roles: [],
          // },
          {
            title: t('user_management'),
            path: paths.hssfPaths.user.management.root,
            icon: ICONS.user,
            roles: [ROLES.superAdmin, ROLES?.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka],
            commonActive: 'user',
          },
          {
            title: t('new_registration.registration_title'),
            path: paths.hssfPaths.registration.list,
            icon: ICONS.user,
            roles: [ROLES.superAdmin, ROLES?.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka],
            commonActive: 'registration-list',
          },
          // ! new - end
          // {
          //   title: t('user_registration'),
          //   path: paths.hssfPaths.user.registration,
          //   icon: ICONS.addUser,
          //   roles: [ROLES.superAdmin, ROLES?.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka],
          // },
          // sanstha - registration
          // {
          //   title: t('sanstha_registration.sanstha_management'),
          //   path: paths.hssfPaths.institution.management.root,
          //   // path: '#',
          //   // disabled: true,
          //   icon: ICONS.ic_institution,
          //   roles: [ROLES.superAdmin, ROLES?.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka],
          //   commonActive: 'institution',
          // },
          // {
          //   title: t('event_management'),
          //   path: paths.hssfPaths.event.root,
          //   icon: ICONS.event,
          //   roles: [ROLES.superAdmin, ROLES?.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka],
          //   commonActive: 'event',
          // },
          // {
          //   title: t('dashboard_tab.report'),
          //   path: paths.hssfPaths.report.root,
          //   // path: '#',
          //   // disabled: true,
          //   icon: ICONS.ic_report,
          //   roles: [ROLES.superAdmin, ROLES?.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka],
          //   commonActive: 'report',
          // },
          // {
          //   title: t('event_calendar'),
          //   path: '#disabled',
          //   icon: ICONS.event_cal,
          //   disabled: true,
          //   roles: [ROLES.superAdmin, ROLES?.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka],
          // },

          // sanstha - registration
          // {
          //   title: t('sanstha_registration.sanstha_reg'),
          //   path: paths.hssfPaths.institution.registration,
          //   icon: ICONS.addUser,
          //   children: [
          //     {
          //       title: t('sanstha_registration.educational'),
          //       path: paths.hssfPaths.institution.educational_reg,
          //     },
          //     {
          //       title: t('sanstha_registration.ngo'),
          //       path: paths.hssfPaths.institution.ngo_reg,
          //     },
          //     {
          //       title: t('sanstha_registration.corporate'),
          //       path: paths.hssfPaths.institution.corporate_reg,
          //     },
          //     {
          //       title: t('sanstha_registration.religious'),
          //       path: paths.hssfPaths.institution.religious_reg,
          //     },
          //   ],
          // },
        ],
      },
      // ---------------------------------------------------------------------------------- HSSF-END

      // {
      //   subheader: t('overview'),
      //   items: [
      //     {
      //       title: t('app'),
      //       path: paths.dashboard.root,
      //       icon: ICONS.dashboard,
      //     },
      //     {
      //       title: t('ecommerce'),
      //       path: paths.dashboard.general.ecommerce,
      //       icon: ICONS.ecommerce,
      //     },
      //     {
      //       title: t('analytics'),
      //       path: paths.dashboard.general.analytics,
      //       icon: ICONS.analytics,
      //     },
      //     {
      //       title: t('banking'),
      //       path: paths.dashboard.general.banking,
      //       icon: ICONS.banking,
      //     },
      //     {
      //       title: t('booking'),
      //       path: paths.dashboard.general.booking,
      //       icon: ICONS.booking,
      //     },
      //     {
      //       title: t('file'),
      //       path: paths.dashboard.general.file,
      //       icon: ICONS.file,
      //     },
      //   ],
      // },

      // // MANAGEMENT
      // // ----------------------------------------------------------------------
      // {
      //   subheader: t('management'),
      //   items: [
      //     // USER
      //     {
      //       title: t('user'),
      //       path: paths.dashboard.user.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: t('profile'), path: paths.dashboard.user.root },
      //         { title: t('cards'), path: paths.dashboard.user.cards },
      //         { title: t('list'), path: paths.dashboard.user.list },
      //         { title: t('create'), path: paths.dashboard.user.new },
      //         { title: t('edit'), path: paths.dashboard.user.demo.edit },
      //         { title: t('account'), path: paths.dashboard.user.account },
      //       ],
      //     },

      //     // PRODUCT
      //     {
      //       title: t('product'),
      //       path: paths.dashboard.product.root,
      //       icon: ICONS.product,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.product.root },
      //         {
      //           title: t('details'),
      //           path: paths.dashboard.product.demo.details,
      //         },
      //         { title: t('create'), path: paths.dashboard.product.new },
      //         { title: t('edit'), path: paths.dashboard.product.demo.edit },
      //       ],
      //     },

      //     // ORDER
      //     {
      //       title: t('order'),
      //       path: paths.dashboard.order.root,
      //       icon: ICONS.order,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.order.root },
      //         { title: t('details'), path: paths.dashboard.order.demo.details },
      //       ],
      //     },

      //     // INVOICE
      //     {
      //       title: t('invoice'),
      //       path: paths.dashboard.invoice.root,
      //       icon: ICONS.invoice,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.invoice.root },
      //         {
      //           title: t('details'),
      //           path: paths.dashboard.invoice.demo.details,
      //         },
      //         { title: t('create'), path: paths.dashboard.invoice.new },
      //         { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
      //       ],
      //     },

      //     // BLOG
      //     {
      //       title: t('blog'),
      //       path: paths.dashboard.post.root,
      //       icon: ICONS.blog,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.post.root },
      //         { title: t('details'), path: paths.dashboard.post.demo.details },
      //         { title: t('create'), path: paths.dashboard.post.new },
      //         { title: t('edit'), path: paths.dashboard.post.demo.edit },
      //       ],
      //     },

      //     // JOB
      //     {
      //       title: t('job'),
      //       path: paths.dashboard.job.root,
      //       icon: ICONS.job,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.job.root },
      //         { title: t('details'), path: paths.dashboard.job.demo.details },
      //         { title: t('create'), path: paths.dashboard.job.new },
      //         { title: t('edit'), path: paths.dashboard.job.demo.edit },
      //       ],
      //     },

      //     // TOUR
      //     {
      //       title: t('tour'),
      //       path: paths.dashboard.tour.root,
      //       icon: ICONS.tour,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.tour.root },
      //         { title: t('details'), path: paths.dashboard.tour.demo.details },
      //         { title: t('create'), path: paths.dashboard.tour.new },
      //         { title: t('edit'), path: paths.dashboard.tour.demo.edit },
      //       ],
      //     },

      //     // FILE MANAGER
      //     {
      //       title: t('file_manager'),
      //       path: paths.dashboard.fileManager,
      //       icon: ICONS.folder,
      //     },

      //     // MAIL
      //     {
      //       title: t('mail'),
      //       path: paths.dashboard.mail,
      //       icon: ICONS.mail,
      //       // info: <Label color="error">+32</Label>,
      //     },

      //     // CHAT
      //     {
      //       title: t('chat'),
      //       path: paths.dashboard.chat,
      //       icon: ICONS.chat,
      //     },

      //     // CALENDAR
      //     {
      //       title: t('calendar'),
      //       path: paths.dashboard.calendar,
      //       icon: ICONS.calendar,
      //     },

      //     // KANBAN
      //     {
      //       title: t('kanban'),
      //       path: paths.dashboard.kanban,
      //       icon: ICONS.kanban,
      //     },
      //   ],
      // },

      // // DEMO MENU STATES
      // {
      //   subheader: t(t('other_cases')),
      //   items: [
      //     {
      //       // default roles : All roles can see this entry.
      //       // roles: ['user'] Only users can see this item.
      //       // roles: ['admin'] Only admin can see this item.
      //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
      //       // Reference from 'src/guards/RoleBasedGuard'.
      //       title: t('item_by_roles'),
      //       path: paths.dashboard.permission,
      //       icon: ICONS.lock,
      //       roles: ['admin', 'manager'],
      //       caption: t('only_admin_can_see_this_item'),
      //     },
      //     {
      //       title: t('menu_level'),
      //       path: '#/dashboard/menu_level',
      //       icon: ICONS.menuItem,
      //       children: [
      //         {
      //           title: t('menu_level_1a'),
      //           path: '#/dashboard/menu_level/menu_level_1a',
      //         },
      //         {
      //           title: t('menu_level_1b'),
      //           path: '#/dashboard/menu_level/menu_level_1b',
      //           children: [
      //             {
      //               title: t('menu_level_2a'),
      //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
      //             },
      //             {
      //               title: t('menu_level_2b'),
      //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
      //               children: [
      //                 {
      //                   title: t('menu_level_3a'),
      //                   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
      //                 },
      //                 {
      //                   title: t('menu_level_3b'),
      //                   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
      //                 },
      //               ],
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       title: t('item_disabled'),
      //       path: '#disabled',
      //       icon: ICONS.disabled,
      //       disabled: true,
      //     },
      //     {
      //       title: t('item_label'),
      //       path: '#label',
      //       icon: ICONS.label,
      //       // info: (
      //       //   <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
      //       //     NEW
      //       //   </Label>
      //       // ),
      //     },
      //     {
      //       title: t('item_caption'),
      //       path: '#caption',
      //       icon: ICONS.menuItem,
      //       caption:
      //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
      //     },
      //     {
      //       title: t('item_external_link'),
      //       path: 'https://www.google.com/',
      //       icon: ICONS.external,
      //     },
      //     {
      //       title: t('blank'),
      //       path: paths.dashboard.blank,
      //       icon: ICONS.blank,
      //     },
      //   ],
      // },
    ],
    [t]
  );

  return data;
}
