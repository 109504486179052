import axios from 'axios';
import { HOST_API } from 'src/config-global';

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

// get all user data
export const getRegisterUserData = async () => {
  // const url = `${API_END_POINTS.AUTH.NEW_REGISTER}`;
  const url = `${process.env.REACT_APP_BACKEND_URL}api/organizations`;
  const { data } = await axiosInstance.get(url, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return data;
};

export const createRegistration = async (payload) => {
  // const url = `${API_END_POINTS.AUTH.NEW_REGISTER}`;
  const url = `${process.env.REACT_APP_BACKEND_URL}api/organization`;
  const { data } = await axiosInstance.post(url, payload, {
    headers: {
      authorization: localStorage.getItem('accessToken')
        ? `Bearer ${localStorage.getItem('accessToken')}`
        : null,
    },
  });
  return data;
};
